import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook,
    faAngleUp,
    faArchive,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowDown19,
    faArrowLeft,
    faArrowRight,
    faArrowUpShortWide,
    faArrowsRotate,
    faArrowsSpin,
    faAward,
    faBackwardStep,
    faBadgeCheck,
    faBanBug,
    faBars,
    faBarsFilter,
    faBarsProgress,
    faBell,
    faBellOn,
    faBinoculars,
    faBolt,
    faBookReader,
    faBooks,
    faBoxArchive,
    faBoxFull,
    faBracketRound,
    faBracketRoundRight,
    faBrain,
    faBug,
    faBuilding,
    faBuildingFlag,
    faBullseyeArrow,
    faCabinetFiling,
    faCalculatorSimple,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChalkboard,
    faChalkboardTeacher,
    faChalkboardUser,
    faChartBar,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronsLeft,
    faChevronsRight,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCircleNotch,
    faCirclePlus,
    faCircleSmall,
    faCircleUser,
    faClipboardList,
    faClock,
    faCloudArrowUp,
    faCommentAltLines,
    faCommentAltSmile,
    faCommentDots,
    faCompressAlt,
    faCopy,
    faCube,
    faCubes,
    faDeleteLeft,
    faDesktop,
    faDiagramProject,
    faDice,
    faDivide,
    faEarthAmericas,
    faEllipsis,
    faEllipsisVertical,
    faEnvelope,
    faEquals,
    faEraser,
    faExclamationCircle,
    faExternalLink,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileContract,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileExport,
    faFileImport,
    faFilePdf,
    faFilePen,
    faFilePlus,
    faFileUpload,
    faFileXmark,
    faFilm,
    faFilters,
    faFlagPennant,
    faFloppyDisk,
    faFolder,
    faFolderPlus,
    faFolders,
    faFont,
    faGauge,
    faGear,
    faGrid2,
    faGridHorizontal,
    faGripDots,
    faGripDotsVertical,
    faHandHoldingSeedling,
    faHeadSideBrain,
    faHome,
    faIdCard,
    faImage,
    faInboxFull,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLaptopHouse,
    faLink,
    faLinkSimple,
    faLinkSlash,
    faList,
    faListTree,
    faListUl,
    faLockAlt,
    faMagnifyingGlass,
    faMagnifyingGlassChart,
    faMinus,
    faNewspaper,
    faNoteSticky,
    faPalette,
    faPaperPlaneTop,
    faPaperclip,
    faPaperclipVertical,
    faPause,
    faPenLine,
    faPenToSquare,
    faPencil,
    faPencilAlt,
    faPersonToPortal,
    faPipe,
    faPlay,
    faPlus,
    faPlusSquare,
    faPoll,
    faPrint,
    faQrcode,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faRadiation,
    faRainbow,
    faRandom,
    faRedo,
    faRightFromBracket,
    faRotateLeft,
    faRotateRight,
    faSave,
    faSchool,
    faSchoolFlag,
    faScreenUsers,
    faScrewdriverWrench,
    faScribble,
    faShapes,
    faShare,
    faShareAlt,
    faShieldCheck,
    faSignalBarsStrong,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faSquare,
    faSquareChevronDown,
    faSquareChevronUp,
    faSquareQuestion,
    faStar,
    faStarShooting,
    faStethoscope,
    faStickyNote,
    faStopwatch,
    faSwap,
    faSyncAlt,
    faTable,
    faTableList,
    faTag,
    faTags,
    faTasks,
    faText,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faTrashCan,
    faTrashRestoreAlt,
    faTrashUndo,
    faTrashXmark,
    faTriangleExclamation,
    faTrophyAlt,
    faUndo,
    faUniversalAccess,
    faUser,
    faUserSecret,
    faUserGroup,
    faUserTie,
    faUsers,
    faUsersClass,
    faVolume,
    faWindowClose,
    faX,
} from '@fortawesome/pro-solid-svg-icons';

import { 
    faFacebook,
    faLinkedin,
    faXTwitter,
    faYoutube 
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faAddressBook,
    faAngleUp,
    faArchive,
    faArrowAltLeft,
    faArrowAltRight,
    faArrowDown19,
    faArrowLeft,
    faArrowRight,
    faArrowUpShortWide,
    faArrowsRotate,
    faArrowsSpin,
    faAward,
    faBackwardStep,
    faBadgeCheck,
    faBanBug,
    faBars,
    faBarsFilter,
    faBarsProgress,
    faBell,
    faBellOn,
    faBinoculars,
    faBolt,
    faBookReader,
    faBooks,
    faBoxArchive,
    faBoxFull,
    faBracketRound,
    faBracketRoundRight,
    faBrain,
    faBug,
    faBuilding,
    faBuildingFlag,
    faBullseyeArrow,
    faCabinetFiling,
    faCalculatorSimple,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChalkboard,
    faChalkboardTeacher,
    faChalkboardUser,
    faChartBar,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronsLeft,
    faChevronsRight,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faCircleNotch,
    faCirclePlus,
    faCircleSmall,
    faCircleUser,
    faClipboardList,
    faClock,
    faCloudArrowUp,
    faCommentAltLines,
    faCommentAltSmile,
    faCommentDots,
    faCompressAlt,
    faCopy,
    faCube,
    faCubes,
    faDeleteLeft,
    faDesktop,
    faDiagramProject,
    faDice,
    faDivide,
    faEarthAmericas,
    faEllipsis,
    faEllipsisVertical,
    faEnvelope,
    faEquals,
    faEraser,
    faExclamationCircle,
    faExternalLink,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileContract,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileExport,
    faFileImport,
    faFilePdf,
    faFilePen,
    faFilePlus,
    faFileUpload,
    faFileXmark,
    faFilm,
    faFilters,
    faFlagPennant,
    faFloppyDisk,
    faFolder,
    faFolderPlus,
    faFolders,
    faFont,
    faGauge,
    faGear,
    faGrid2,
    faGridHorizontal,
    faGripDots,
    faGripDotsVertical,
    faHandHoldingSeedling,
    faHeadSideBrain,
    faHome,
    faIdCard,
    faImage,
    faInboxFull,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLaptopHouse,
    faLink,
    faLinkSimple,
    faLinkSlash,
    faList,
    faListTree,
    faListUl,
    faLockAlt,
    faMagnifyingGlass,
    faMagnifyingGlassChart,
    faMinus,
    faNewspaper,
    faNoteSticky,
    faPalette,
    faPaperPlaneTop,
    faPaperclip,
    faPaperclipVertical,
    faPause,
    faPenLine,
    faPenToSquare,
    faPencil,
    faPencilAlt,
    faPersonToPortal,
    faPipe,
    faPlay,
    faPlus,
    faPlusSquare,
    faPoll,
    faPrint,
    faQrcode,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faRadiation,
    faRainbow,
    faRandom,
    faRedo,
    faRightFromBracket,
    faRotateLeft,
    faRotateRight,
    faSave,
    faSchool,
    faSchoolFlag,
    faScreenUsers,
    faScrewdriverWrench,
    faScribble,
    faShapes,
    faShare,
    faShareAlt,
    faShieldCheck,
    faSignalBarsStrong,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faSquare,
    faSquareChevronDown,
    faSquareChevronUp,
    faSquareQuestion,
    faStar,
    faStarShooting,
    faStethoscope,
    faStickyNote,
    faStopwatch,
    faSwap,
    faSyncAlt,
    faTable,
    faTableList,
    faTag,
    faTags,
    faTasks,
    faText,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faTrashCan,
    faTrashRestoreAlt,
    faTrashUndo,
    faTrashXmark,
    faTriangleExclamation,
    faTrophyAlt,
    faUndo,
    faUniversalAccess,
    faUser,
    faUserSecret,
    faUserGroup,
    faUserTie,
    faUsers,
    faUsersClass,
    faVolume,
    faWindowClose,
    faX
);

library.add(
    faFacebook,
    faLinkedin,
    faXTwitter,
    faYoutube,
    faHome,
);
